html,body {
    font-family: $base-font-family;
    font-size: $base-font-size;
}

.navbar {
    border-radius: 0;
}

.navbar-default {
    border-color: transparent;
    background-color: transparent;
    padding: 10px 0;
//    border-bottom: 1px solid #eee;
}

img {
    max-width: 100%;
}

.mt50 {
    margin-top: 50px;
}
.mt20 {
    margin-top: 20px;
}

.mt10 {
    margin-top: 10px;
}
.mt25 {
    margin-top: 0;
    @include media-query($on-palm) {
        margin-top: 25px;
    }
}

.p20{
    padding: 20px 0;
}
.navbar-brand {
    font-weight: 900;
    font-size: 1.85em;
    padding: 10px;
    @media screen and (max-width: $on-palm) {
        font-size: 1.5em;
    }
}
.post-meta {
    color: $grey-color;
}

.panel {
    
   border-radius: 0;
   border: 1px solid #eee;
   transition: all 0.3s ease;
}

.panel:hover {
    box-shadow: 0px 0px 4px #ddd;
}


footer {
    border-top: 1px solid $grey-color-light;
}

.social {
    list-style: none;
    display: inline;
    margin-left: 10px;
    padding: 5px 8px;
    }
.social:hover {
     opacity: 0.7;
}
.social img {
    width: 20px;
}

.quora img{
    width: 17px;
}

.facebook img {
    width: 16px;
}

.recent ul {
    list-style: none;
    padding: 0;
    text-align: justify;
}

.recent {
    padding: 10px;
    border: 1px solid $grey-color-light;
}

.post-img img {
    width: 100%;
}


.pack {


    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}

.card {
    float: none;

}

.post-title {
    line-height: 2;
}
